<template>
  <section class="pad-y-60 pad-b-30 text-center">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="title font-secondary fs-40 text-teal-dark">بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ</h2>
        <div class="sub-title text-uppercase" style="font-size: 1.25rem; letter-spacing: 2px">Assalamu`alaikumWarahmatullaahi Wabarakaatuh</div>
      </div>

      <div
        class="font-weight-lighter mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        <!-- <div class="mb-2">
          Tuhan membuat segala sesuatu indah pada waktunya. Indah saat Dia
          mempertemukan, Indah saat Dia menumbuhkan kasih, dan indah saat Dia
          Mempersatukan putra putri kami dalam suatu ikatan pernikahan Kudus
        </div>
        <div>
          Dengan segala kerendahan hati dan dengan ungkapan syukur atas karunia
          Tuhan, kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri Acara
          Pernikahan putra-putri kami yang akan diselenggarakan pada :
        </div> -->
        <div>
          Dengan memohon Rahmat dan Ridho Allah SWT Yang telah menciptakan makhluk-nya berpasangan-pasangan, Kami bermaksud menyelenggarakan pernikahan kedua putra dan putri kami pada
        </div>
      </div>

      
      <div class="row py-3">
        <div class="col-lg-6">
          <Event
            :location="{ address: $parent.location }"
            image="https://images.unsplash.com/photo-1522673607200-164d1b6ce486?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&h=700&q=80"
            start="2022-01-30 09:00"
            end=""
            title="Akad"
            data-aos="fade-right"
            data-aos-delay="300"
          />
        </div>
        <div class="col-lg-6">
          <Event
            :location="{ address: $parent.location }"
            image="https://images.unsplash.com/photo-1529636695044-9e93499f4de3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80"
            start="2022-01-30 18:00"
            end="Selesai"
            title="Resepsi"
            data-aos="fade-left"
            data-aos-delay="300"
          />
        </div>
      </div>

      <p class="font-weight-lighter" data-aos="fade-left" data-aos-delay="600">
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada
        putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <h3
        class="font-secondary text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        {{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}
      </h3>
      <!-- <p class="font-secondary" data-aos="fade-up" data-aos-delay="800">
        Om Santhi Santhi Santhi Om
      </p> -->
    </div>
  </section>
</template>

<script>
import Event from "./Event.vue";
export default { components: { Event } };
</script>