<template>
  <div class="event">
    <div class="image">
      <img :src="image" />
      <!-- <div class="overlay"></div> -->
    </div>
    <div class="event-body text-left">
      <h3>{{ title }}</h3>
      <ul class="list-unstyled">
        <li>
          <i class="material-icons">event</i>
          <span>{{ $moment(start).format("dddd, DD MMMM YYYY") }}</span>
        </li>
        <li>
          <i class="material-icons">schedule</i>
          <span>{{ $moment(start).format("HH:mm") }} - {{ end }} WIB</span>
        </li>
        <li>
          <i class="material-icons">place</i>
          <span v-html="location.address"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AGWMap from "@/components/Maps";

export default {
  components: {
    AGWMap,
  },
  props: {
    location: Object,
    title: String,
    start: String,
    end: String,
    image: String,
  }
};
</script>

<style scoped lang="scss">
.event {
  box-shadow: 0 3px 15px rgba(0, 0, 0, .15);
  background: #ffffff;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;

  .btn {
    letter-spacing: 3px;
  }
}

.event-body {
  padding: 1rem;

  h3 {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  ul {
    margin-top: 1rem;

    li {
      display: flex;
      // align-items: center;
      margin-bottom: .75rem;
      font-size: 1.1rem;

      i {
        margin-right: .5rem;
        margin-top: .2rem;
        color: #444;
      }
    }
  }
}

.image {
  height: 195px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    opacity: .25;
  }

  img {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-position: center;
  }

  h3 {
    z-index: 10;
    position: relative;
    color: rgb(2, 2, 2);
    text-transform: uppercase;
    letter-spacing: 3px;
    text-shadow: 0 2px 15px rgba(0, 0, 0, .2);
  }
}
</style>
